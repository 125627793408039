
html { font-family: 'Univers';
    src: url('./fonts/UniversLTStd.otf'); font-size:14pt; background-color:white }
a.comment-indicator:hover + div.comment { background:#ffd; position:absolute; display:block; border:1px solid black; padding:0.5em }
a.comment-indicator { background:red; display:inline-block; border:1px solid black; width:0.5em; height:0.5em }
div.comment { display:none }
table { border-collapse:collapse; page-break-after:always; width: 100%; }
.gridlines td { border:1px dotted black }
.gridlines th { border:1px dotted black }
.b { text-align:center }
.e { text-align:center }
.f { text-align:right }
.inlineStr { text-align:left }
.n { text-align:right }
.s { text-align:left }
td.style0 { vertical-align:bottom; border-bottom:none #000000; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
th.style0 { vertical-align:bottom; border-bottom:none #000000; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
td.style1 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
th.style1 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
td.style2 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:1px solid #000000 !important; border-left:1px solid #000000 !important; border-right:1px solid #000000 !important; color:#000000;  font-size:14pt; background-color:white }
th.style2 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:1px solid #000000 !important; border-left:1px solid #000000 !important; border-right:1px solid #000000 !important; color:#000000;  font-size:14pt; background-color:white }
td.style3 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:1px solid #000000 !important; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
th.style3 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:1px solid #000000 !important; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
td.style4 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
th.style4 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
td.style5 { vertical-align:middle; text-align:center; border-bottom:1px solid #000000 !important; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
th.style5 { vertical-align:middle; text-align:center; border-bottom:1px solid #000000 !important; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
td.style6 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:1px solid #000000 !important; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
th.style6 { vertical-align:middle; text-align:center; border-bottom:none #000000; border-top:1px solid #000000 !important; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
td.style7 { vertical-align:middle; text-align:center; border-bottom:1px solid #000000 !important; border-top:1px solid #000000 !important; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
th.style7 { vertical-align:middle; text-align:center; border-bottom:1px solid #000000 !important; border-top:1px solid #000000 !important; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
td.style8 { vertical-align:middle; text-align:center; border-bottom:1px solid #000000 !important; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
th.style8 { vertical-align:middle; text-align:center; border-bottom:1px solid #000000 !important; border-top:none #000000; border-left:none #000000; border-right:none #000000; color:#000000;  font-size:14pt; background-color:white }
table.sheet0 col.col0 { width:50.83333275pt }
table.sheet0 col.col1 { width:65.74444369pt }
table.sheet0 col.col2 { width:62.35555484pt }
table.sheet0 col.col3 { width:42pt }
table.sheet0 col.col4 { width:42pt }
table.sheet0 col.col5 { width:42pt }
table.sheet0 col.col6 { width:42pt }
table.sheet0 col.col7 { width:42pt }
table.sheet0 col.col8 { width:65.74444369pt }
table.sheet0 tr { height:15pt }