.button {
    min-width: 7rem;
    width: auto;
    max-width: 12rem;
    box-shadow: 0px 5px 5px #00000082;
    font: bold 1.2rem arial,sans-serif;
    text-shadow: 0.3rem;
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    padding: 1em;
    }
.button:active {
    opacity: 0.7;
    transition: 0.1s
        }

.primary{
    background: #006f35;
    color: white;
    border-style:hidden;
    border-radius: 0.8rem;
}

.secondary{
    background-color: white;
    border-style: solid;
    border-radius: 0.8rem;
    border-color: #006f35;
    color: #006f35;
}

.tertiary{
    background-color: #6e867d;
    border-style: solid;
    border-radius: 0.8rem;
    border-color: #006f35;
    color: white;
}