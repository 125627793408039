@font-face {
  font-family: 'Univers';
  src: url('./fonts/UniversLTStd.otf');
}

* {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

.content {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}


.flex_margin{
  margin-top: 0px;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.flex_container {
  max-width: 1200px;
  min-width: 500px;
  min-height: 300px;
  margin: 2em auto 2em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  padding: 20px;
  font-family: 'Univers';
  font-stretch: expanded;
}

/* Para larguras menores que 900 pixels */
@media (max-width: 900px) {
.flex_container{
  
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
}
    .parent {
      display: grid;
      grid-template-columns: repeat(2,);
      grid-template-rows: repeat(3, fit-content);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      }
      
      .div1 {
        grid-area: 1 / 1 / 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content; 
      }
      .div2 {
        grid-area: 2 / 1 / 3 / 3;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
      }
      .div3 { 
        grid-area: 3 / 1 / 4 / 3; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
      }
      .div4 {
        grid-area: 4 / 1 / 5 / 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 80px;
      }

      .flex_block {
        display: flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items: center;
        max-width: 100%;
        border-radius: 10px;
        margin-top: 0.3rem;
        margin-bottom: 0.1rem;
      
        /*background-color: rgba(237, 235, 235, 0.96);*/
      }

      .flex_block_var_1_ {
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        width:100%;
        max-width: 800px;
        border-radius: 10px;
      
        /*background-color: rgba(237, 235, 235, 0.96);*/
      }

      .div4_var_1_ {
        grid-area: 4 / 1 / 5 / 3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:center;
        flex-wrap: wrap;
        max-width: 100%;
        height: fit-content;
      }
      
      .flex_line_var_1_ {
        display: flex;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
        width:100%;
        margin: 1rem;
        
    }

}

/* Para larguras maiores que 900 pixels */
@media (min-width: 901px) {
    .parent {
      display: grid;
      grid-template-columns: repeat(2);
      grid-template-rows: repeat(3, fit-content);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      }


      .div1 {
        grid-area: 1 / 1 / 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }      
      .div2 {
        grid-area: 2 / 1 / 3 / 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
      }
      .div3 {
        grid-area: 2 / 2 / 3 / 3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
      }
      .div4 {
        grid-area: 3 / 1 / 4 / 3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:flex-end;
        flex-wrap: wrap;
        max-width: 100%;
        height: fit-content;
      }

      .div4_dif{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;
        height: fit-content;
      }
}
@media (min-width: 901px) {
  .parent_var_1_ {
    display: grid;
    grid-template-columns: repeat(2);
    grid-template-rows: repeat(3, fit-content);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100%;
    }
    
    .div1_var_1_ {
      grid-area: 1 / 1 / 2 / 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }      
    .div2_var_1_ {
      grid-area: 2 / 1 / 3 / 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      
    }
    .div3_var_1_ {
      grid-area: 3 / 1 / 4 / 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }
    .div4_var_1_ {
      grid-area: 4 / 1 / 5 / 3;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items:flex-end;
      flex-wrap: wrap;
      max-width: 100%;
      height: fit-content;
    }

    .flex_line_var_1_ {
      display: flex;
      flex-direction: row;
      align-content: center;
      flex-wrap: wrap;
      width:50%;
      min-width: 900px;
      max-width: fit-content;
      margin: 10px auto;
      border-radius: 10px;
      
  }
  .flex_block_var_1_ {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    width:100%;
    max-width: 800px;
    border-radius: 10px;
  
    /*background-color: rgba(237, 235, 235, 0.96);*/
  }
}
.margin_align{
  padding-left: 50px;
}


.flex_block {
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  max-width: 100%;
  border-radius: 10px;

  /*background-color: rgba(237, 235, 235, 0.96);*/
}

.flex_block_column {
  display: flex;
  flex-direction: column;
  justify-content:center;
  min-width: fit-content;
  max-width: 100%;
  border-radius: 10px

  /*background-color: rgba(237, 235, 235, 0.96);*/
}


.flex_line {
  display: flex;
  flex-direction: row;
  align-content:center;
  flex-wrap: nowrap;
  width:50%;
  min-width: 450px;
  max-width: fit-content;
  margin: 10px auto;
  border-radius: 10px;
 
  /*background-color: rgba(225, 225, 225, 0.685);
  /*background-color: rgba(248, 0, 0, 0.96);*/
}

.width{
  width: 100% !important;
}

.width_50{
  width: 80% !important;
}

.width_5{
  width: 70% !important;
}
.min_width{
  min-width: 600px;
}

.flex_nowrap{
  flex-wrap: nowrap;
}
.flex_wrap{
  flex-wrap: wrap;
}

.flex_jc_spacebetween{
  justify-content:space-between !important;

}
.flex_jc_evenly{
  justify-content:space-evenly !important;
}
.flex_jc_center{
  justify-content: center !important;
}

.flex_jc_spacearound{
  justify-content: space-around !important;
}
.bg-grey{
  background-color: rgba(225, 225, 225, 0.685);
}

h1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2rem;
  text-align: center;
  width: 100%;
}

h2{
  font-size: 1.2rem;
  padding: 3px;
}

p {
  text-align:justify;
  margin: 0 8px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}

label{
  font-size: 18px;
  font-weight: 400;
  padding: 2px;
}

.input{
  margin: 7px;
}
.p_center{
  text-align: center !important;
}
.p_justify{
  text-align: justify !important;
}

.p_mark {
  list-style: circle;
  list-style-position: inside;
  padding: 0;
  margin: 5px;
}

ul{
  margin: 0;
}
.table {
  border-collapse: collapse;
  border: 1px solid black;
  width: 50%;
  min-width: 600px;
}

.table th, .table td {
  border: 2px solid black;
  padding: 8px;
  text-align:justify;
  margin: 0 4px;
  font-size: 20px;
  font-weight: 500;
}


li{
  text-align:justify;
  margin: 5px 8px;
  font-size: 20px;
  font-weight: 550;
  width: 100%;
}
.paragraphs {
  width:100%;
  margin: 1rem;
  overflow-wrap:break-word;
}

.margin_bot{
  margin-bottom: 5px;
}
.margin_top{
  margin-top: 20px;
}
.margin_top_2{
  margin-top: 0.8rem;
}
.paddint_top{
  padding-top: 2.5rem;
}

.height{
  height: 4rem !important;
}
.margin_auto_TB{
  margin-bottom: auto;
  margin-top: auto;
}


.margin_auto_LR{
  margin-left: 50%;
  margin-right: auto;
}

.margin_custom{
  margin: 2px;
}
.margin_custom_5{
  margin: 5px;
}


.strong {
  font-weight: bold;
}

.paddint_bot{
  padding-bottom: 10px;
}

.margin_LF{
  margin-left: 3rem;
  margin-right: 3rem;
}

.output_li{
  min-width: 4rem;
  width: 100%;
}
.border{
  border-radius: 10px;
}
