.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-icon {
    position: relative;
    display:flex;
    flex-wrap:nowrap;
    align-items: stretch;
    
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 0.8;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 0.1rem solid rgb(106, 106, 106);
    border-radius: 0.2rem;
    box-shadow: 0px 1px 1px #00000082;
    max-width: 3rem;
    overflow: hidden;
    cursor: pointer;

}

.border_left{
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
}

.border_right{
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

 
  .teste:active{
    opacity: 0.5;
    transition: 0.2s
  }
  
  .disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }