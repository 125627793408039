.button_small {
    min-width: 3rem !important;
    width: auto  !important;
    max-width: 12rem  !important;
    height: 1rem  !important;
    box-shadow: 0px 5px 5px #00000082  !important;
    font: bold 1rem arial,sans-serif  !important;
    text-shadow: 0.3rem  !important;
    text-decoration: none  !important;
    text-align: center  !important;
    text-transform: none !important;

    padding: 1em  !important;

    background: #006f35  !important;
    color: white  !important;
    border-style:hidden  !important;
    border-radius: 0.8rem  !important;
    }
.button_small:active {
    opacity: 0.7  !important;
    transition: 0.1s  !important
        }

.primary{
    background: #006f35;
    color: white;
    border-style:hidden;
    border-radius: 0.8rem;
}

.secondary{
    background-color: white;
    border-style: solid;
    border-radius: 0.8rem;
    border-color: #006f35;
    color: #006f35;
}

.tertiary{
    background-color: #6e867d;
    border-style: solid;
    border-radius: 0.8rem;
    border-color: #006f35;
    color: white;
}